// custom typefaces
require("typeface-montserrat")
require("typeface-merriweather")

// normalize CSS across browsers
require("./src/css/normalize.css")
// custom CSS styles
require("./src/css/style.css")
// custom Bootstrap styles
require("./src/css/bootstrap.css")

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]))
  }, 100)

  return false
}
